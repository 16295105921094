import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Shared.module.css';

const PinEntry = ({ onPinEntered }) => {
  const [pin, setPin] = useState('');
  const navigate = useNavigate();
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        handlePinSubmit(e);
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [pin]);

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 4) {
      setPin(value);
    }
  };

  const handlePinSubmit = (e) => {
    e.preventDefault();
    if (pin === '19') {
      navigate('/goteem');
    } else if (pin === '3') {
      navigate('/hello');
    } else {
      onPinEntered(pin);
    }
  };

  return (
    <div className={styles.PinContainer}>
      <div className={styles.formContainer}>
        <form className={styles.form} onSubmit={handlePinSubmit}>
          <input
            ref={inputRef}
            className={styles.input}
            type="password"
            value={pin}
            onChange={handleChange}
            maxLength="4"
          />
          <button type="submit" className={styles.pinbutton}>Submit</button>
        </form>
      </div>
    </div>
  );
};

export default PinEntry;

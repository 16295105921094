import React from 'react';
import styles from './styles.module.css';

const Hello = () => {
  return (
    <div className={styles.GifContainer}>
      <h1> Hello </h1>
    </div>
  );
};

export default Hello;

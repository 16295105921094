import React from 'react';
import styles from './styles.module.css';
import goteemGif from './goteem.gif';

const Goteem = () => {
  return (
    <div className={styles.GifContainer}>
        <p className={styles.text}>Deez Nuts</p>
      <img src={goteemGif} alt='goteem' className={styles.gif} />
    </div>
  );
};

export default Goteem;

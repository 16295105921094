import React from 'react';

const NotFound = () => {
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      flexDirection: 'column',
      textAlign: 'center',
      width: '60%',
      margin:'0 auto',
    },
    heading: {
      fontSize: '40px',
    },
    paragraph: {
      fontSize: '20px'
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>404 Page Not Found</h1>
      <p style={styles.paragraph}>The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Shared.module.css';

function Header({ toggleLightMode, isLightMode }) {
  const location = useLocation();

  const scrollToFooter = () => {
    const footer = document.getElementById('footer');
    if (footer) {
      footer.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const renderLinksButton = () => {
    if (location.pathname === '/' || location.pathname === '/projects') {
      return (
        <span
          className={`${styles.navLink} ${isLightMode ? styles.lightMode : ''}`}
          onClick={scrollToFooter}
        >
          Links
        </span>
      );
    }
    return (
      <Link
        to="/projects"
        className={`${styles.navLink} ${isLightMode ? styles.lightMode : ''}`}
      >
        Links
      </Link>
    );
  };

  return (
    <nav className={styles.NavBar}>
      <div className={styles.NavContainer}>
        <ul className={styles.NavList}>
          <li>
            <Link
              to="/"
              className={`${styles.navLink} ${isLightMode ? styles.lightMode : ''}`}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/projects"
              className={`${styles.navLink} ${isLightMode ? styles.lightMode : ''}`}
            >
              Projects
            </Link>
          </li>
          <li>{renderLinksButton()}</li>
        </ul>
        <i
          className={isLightMode ? 'fa-regular fa-moon' : 'fa-solid fa-moon'}
          onClick={toggleLightMode}
          style={{ cursor: 'pointer' }}
        ></i>
      </div>
    </nav>
  );
}

export default Header;

import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PinEntry from './components/portfolio/components/shared/Pin';
import styles from './index.module.css';
import NotFound from './components/NotFound';
import Goteem from './components/garbage/Goteem';
import Hello from './components/garbage/Hello';

import Header from './components/portfolio/components/shared/Header'; 

const Home = lazy(() => import('./components/portfolio/pages/Home'));
const Projects = lazy(() => import('./components/portfolio/pages/Projects'));
const RetCalc = lazy(() => import('./components/Ret_calc/RetCalc'));
const NNVisualiser = lazy(() => import('./components/NN_visualiser/NNVisualiser'));
const Newspaper = lazy(() => import('./components/Newspaper/Newspaper'));
const MNIST = lazy(() => import('./components/MNIST/MNIST'));
const ETLscraper = lazy(() => import('./components/ETL_scraper/ETLscraper'));
const ETLpipeline = lazy(() => import('./components/ETL_pipeline/ETLpipeline'));
const TicTacToe = lazy(() => import('./components/TicTacToe/TicTacToe'));
const FoodFinder = lazy(() => import('./components/FoodFinder/FoodFinder')); 

const apiUrl = process.env.REACT_APP_API_URL;

function App() {
  const [accessGranted, setAccessGranted] = useState(false);
  const [csrfToken, setCsrfToken] = useState(null);
  const [isLightMode, setIsLightMode] = useState(true);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await fetch(`${apiUrl}/get_csrf_token/`, {
          method: 'GET',
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setCsrfToken(data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };

    fetchCsrfToken();
  }, []);

  const getCsrfTokenFromCookie = () => {
    const csrfTokenFromCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
      return csrfTokenFromCookie;
  };

  const verifyPin = async (pin) => {
    try {
      const csrfTokenFromCookie = getCsrfTokenFromCookie();

      if (!csrfTokenFromCookie) {
        throw new Error('CSRF token not found in cookies');
      }
      const response = await fetch(`${apiUrl}/verify_pin/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfTokenFromCookie,
        },
        credentials: 'include',
        body: JSON.stringify({ pin }),
      });

      if (!response.ok) {
        throw new Error('Failed to verify PIN');
      }

      const data = await response.json();
      if (data.valid) {
        localStorage.setItem('PIN', pin);
        setAccessGranted(true);
      } else {
        alert('Incorrect PIN');
      }
    } catch (error) {
      console.error('Error:', error.message);
      alert('Failed to verify PIN');
    }
  };

  const handlePinEntered = (pin) => {
    verifyPin(pin);
  };

  useEffect(() => {
    const storedPIN = localStorage.getItem('PIN');
    if (storedPIN) {
      verifyPin(storedPIN);
    }
  }, [csrfToken]);

  const toggleLightMode = () => {
    setIsLightMode(!isLightMode);
  };

  return (
    <div className={`${styles.MainApp} ${isLightMode ? styles.lightMode : ''}`}>
      <Router>
        {accessGranted && <Header toggleLightMode={toggleLightMode} isLightMode={isLightMode} />}
        <Routes>
          {!accessGranted && (
            <Route
              path="/"
              element={<PinEntry onPinEntered={handlePinEntered} />}
            />
          )}
          {accessGranted && (
            <>
              <Route
                path="/"
                element={
                  <Suspense fallback={<div className={styles.loadingContainer}>Loading...</div>}>
                    <Home isLightMode={isLightMode} />
                  </Suspense>
                }
              />
              <Route
                path="/projects"
                element={
                  <Suspense fallback={<div className={styles.loadingContainer}>Loading...</div>}>
                    <Projects isLightMode={isLightMode} />
                  </Suspense>
                }
              />
              <Route
                path="/retcalc"
                element={
                  <Suspense fallback={<div className={styles.loadingContainer}>Loading...</div>}>
                    <RetCalc isLightMode={isLightMode} />
                  </Suspense>
                }
              />
              <Route
                path="/nnvisualiser"
                element={
                  <Suspense fallback={<div className={styles.loadingContainer}>Loading...</div>}>
                    <NNVisualiser />
                  </Suspense>
                }
              />
              <Route
                path="/newspaper"
                element={
                  <Suspense fallback={<div className={styles.loadingContainer}>Loading...</div>}>
                    <Newspaper isLightMode={isLightMode}/>
                  </Suspense>
                }
              />
              <Route
                path="/mnist"
                element={
                  <Suspense fallback={<div className={styles.loadingContainer}>Loading...</div>}>
                    <MNIST isLightMode={isLightMode} />
                  </Suspense>
                }
              />
              <Route
                path="/etlscraper"
                element={
                  <Suspense fallback={<div className={styles.loadingContainer}>Loading...</div>}>
                    <ETLscraper />
                  </Suspense>
                }
              />
              <Route
                path="/etlpipe"
                element={
                  <Suspense fallback={<div className={styles.loadingContainer}>Loading...</div>}>
                    <ETLpipeline />
                  </Suspense>
                }
              />
              <Route
                path="/tictactoe"
                element={
                  <Suspense fallback={<div className={styles.loadingContainer}>Loading...</div>}>
                    <TicTacToe />
                  </Suspense>
                }
              />
              <Route
                path="/foodfinder"
                element={
                  <Suspense fallback={<div className={styles.loadingContainer}>Loading...</div>}>
                    <FoodFinder />
                  </Suspense>
                }
              />
            </>
          )}
          <Route path="/goteem" element={<Goteem />} />
          <Route path="/hello" element={<Hello />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
